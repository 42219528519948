import AboutInfo from './components/AboutInfo'
import ContactSnippit from './components/ContactSnippit'
import Hero from './components/Hero'
import Team from './components/Team'
import { ImageOptions } from './models'

export function TeamPage() {
    return (
        <div>
            <Hero title="About Us" isMain={false} image={ImageOptions.about} />
            <AboutInfo />
            <Team />
            <ContactSnippit />
        </div>
    )
}
