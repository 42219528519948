import larry1 from '../images/larry1.webp'
import tyler1 from '../images/tyler1.webp'

const Team = () => {
    return (
        <section className="flex flex-col items-center bg-backgroundpurple p-10">
            <article className="text-center sm:max-w-[70%]">
                <h2 className="mb-2 text-[20px] uppercase text-healthpurple lg:text-[30px]">
                    Team
                </h2>
                <h2 className="text-[25px] font-extrabold text-white lg:text-[30px]">
                    Our Leadership
                </h2>
                <p className="mt-2 mb-2 mb-5 text-center text-white lg:text-[25px]">
                    At Healthcare Systems and Services, we are dedicated to
                    building strong, personal relationships with our clients and
                    providing exceptional accounting services tailored to the
                    unique needs of nursing homes. As a family-owned business,
                    we understand the importance of trust and transparency, and
                    we are committed to helping our clients succeed and grow.
                    With our deep industry knowledge and expertise, we are the
                    go-to source for reliable, trustworthy accounting support
                    for nursing homes.
                </p>
            </article>

            <article>
                <div className="py-10 sm:mt-10 sm:flex sm:justify-around">
                    <img
                        src={larry1}
                        alt="Larry Litman"
                        loading="lazy"
                        className="mx-auto mt-10 max-h-[343px] sm:m-0 sm:w-[350px] lg:max-h-[550px] lg:w-[550px]"
                    />
                    <div className="sm:my-auto sm:max-w-[200px] sm:content-center lg:max-w-[500px]">
                        <h3 className="mt-10 text-[25px] font-bold text-white sm:m-0 sm:leading-none lg:text-[30px]">
                            Larry Litman
                        </h3>
                        <h4 className="text-[20px] text-white lg:text-[30px]">
                            Owner and Accountant
                        </h4>
                        <p className="mt-5 text-[15px] text-white sm:max-w-[500px] lg:text-[20px]">
                            Lawrence Litman graduated from Suffolk University
                            with a Bachelor of Science degree in Accounting. He
                            has worked in the Healthcare Industry for 25 years.
                            His experience began in Public Accounting where he
                            was responsible for the audits of Skilled Nursing
                            Facilities, Medical Clinics, and Physician Offices.
                        </p>
                    </div>
                </div>
                <p className="text-[15px] text-white lg:text-[20px] 2xl:px-48">
                    He spent 6 years with Life Care Centers of America where he
                    held positions beginning with Regional Controller,
                    Reimbursement Specialist, and Division Director of Managed
                    Care. He then worked two years at Therapy Network, LLC,
                    helping to assist their clients with the transition between
                    Medicare and Managed Care Pricing. For the past 15 years he
                    has been the President and Sole Proprietor of Healthcare
                    Systems & Services, a financial consulting group focusing on
                    Skilled Nursing Facilities, Continuing Care Retirement
                    Communities, and Hospice Organizations. His company
                    currently works with a large number of Independent and small
                    chain operators, with focus on improving the financial
                    performance by implementing specialized cost analysis tools
                    and reports. In addition, Larry has worked on the committee
                    that developed the Medicaid Cost Report for Arizona, as well
                    as works very closely with the Arizona Health Care
                    Association to maintain and increase Long Term Care Rates in
                    this very unique Managed Care market. He was also awarded
                    the Business Affiliate of the Year award for 2007.
                </p>
            </article>

            <article>
                <div className="py-10 sm:mt-10 sm:flex sm:flex-row-reverse sm:justify-around">
                    <img
                        src={tyler1}
                        alt="Tyler Litman"
                        className="mx-auto mt-10 max-h-[343px] sm:m-0 sm:w-[350px] lg:max-h-[550px] lg:w-[550px]"
                        loading="lazy"
                    />
                    <div className="sm:my-auto sm:max-w-[200px] sm:content-center lg:max-w-[500px]">
                        <h3 className="mt-10 text-[25px] font-bold text-white sm:m-0 sm:leading-none lg:text-[30px]">
                            Tyler Litman
                        </h3>
                        <h4 className="text-[20px] text-white lg:text-[30px]">
                            Accountant and Technologies
                        </h4>
                        <p className="mt-5 text-[15px] text-white sm:max-w-[500px] lg:text-[20px]">
                            Tyler Litman graduated from Barrett Honors College
                            at Arizona State University in 2013 with a Bachelor
                            of Arts in Accounting as well as a Bachelor of
                            Sciences in Computer Science. During this time, he
                            worked as a Technology Support Technician for the
                            ASU Help Center while also automating monthly tasks
                            and internalizing
                        </p>
                    </div>
                </div>
                <p className="text-[15px] text-white lg:text-[20px] 2xl:px-48">
                    server and network operations, maintaining databases,
                    computer operations, and creating data mining programs for
                    Healthcare System & Services. While also seeking a degree in
                    accounting, he assisted in preparation of Federal and State
                    cost reports, monthly financial preparation, and patient
                    detail reporting. Since 2013, Tyler has been working full
                    time with Healthcare Systems & Services preparing
                    financials, monthly reports, as well as federal and state
                    cost reports. In addition, he maintains internally and
                    externally facing technologies and continues to seek out new
                    ways to enhance productivity and reporting through emerging
                    technologies.
                </p>
            </article>
        </section>
    )
}

export default Team
