import Button from './Button'
import { useLocation } from 'react-router-dom'

const ContactSnippit = () => {
    const location = useLocation()
    const backgroundColor = (): string => {
        return location.pathname === '/services' ? 'bg-[#F7F7F7]' : ''
    }

    return (
        <section
            className={`mt-5 flex flex-col items-center p-10 md:h-[500px] md:justify-center ${backgroundColor()}`}
        >
            <h2 className="mb-2 text-[20px] uppercase text-healthpurple xl:text-[30px]">
                Contact
            </h2>
            <h3 className="text-center text-[25px] font-bold leading-none text-backgroundpurple xl:text-[30px]">
                We are constantly
            </h3>
            <h3 className="text-center text-[25px] font-bold leading-none text-backgroundpurple xl:text-[30px]">
                Looking for new challenges
            </h3>
            <p className="mt-5 text-center font-[18px] xl:w-[1300px] xl:text-[25px]">
                Thank you for considering Healthcare Systems and Services for
                your accounting needs. We would love the opportunity to speak
                with you and learn more about your business. We look forward to
                hearing from you!
            </p>
            <Button
                action="/contact/"
                text="Contact Us"
                customStyles="border-black border-[1px] w-[170px] mt-5"
                ariaText="Go to the contact page"
            />
        </section>
    )
}

export default ContactSnippit
