import Button from './Button'

const Hero = (props: { title: string; isMain: boolean; image: string }) => {
    const getImage = (): string => {
        switch (props?.image) {
            case 'home':
                return 'bg-heroMobile sm:bg-heroDesktop'
            case 'about':
                return 'bg-aboutMobile sm:bg-aboutDesktop'
            case 'service':
            case 'contact':
                return 'bg-serviceMobile sm:bg-serviceDesktop'
            default:
                return ''
        }
    }

    return (
        <section
            className={`hero-overlay flex h-[350px] items-center justify-center bg-cover bg-center bg-no-repeat ${getImage()} sm:h-[840px]`}
        >
            {props?.isMain ? (
                <article className="mt-4 flex flex-col content-center justify-center text-center font-mono text-white">
                    <h1 className="text-[25px] uppercase xl:text-[45px]">
                        We Provide
                    </h1>
                    <h1 className="text-[25px] font-extrabold uppercase xl:text-[45px]">
                        Accounting Solutions
                    </h1>
                    <h1 className="font-sans text-[16px] xl:text-[30px]">
                        For a changing market
                    </h1>
                    <div>
                        <Button
                            customStyles="bg-healthpurple mt-3"
                            text="Explore Now"
                            action="services/"
                            ariaText="Go to the services page"
                        />
                    </div>
                </article>
            ) : (
                <h2 className="text-[30px] font-extrabold uppercase text-white">
                    {props?.title}
                </h2>
            )}
        </section>
    )
}

export default Hero
