import React from 'react'
import Service from './ServiceHome'

const servicesOffered = [
    {
        title: 'Annual Services',
        description:
            'We provide a number of yearly services such as cost report preparation, year-end audits, "what-if" scenarios and location specific Medicare…',
        image: 'service1',
        hasButton: true,
    },
    {
        title: 'Monthly Services',
        description:
            'For monthly services we provide financial statement preparation, monthly business audits for accuracy of ancillary services, specialty…',
        image: 'service2',
        hasButton: true,
    },
    {
        title: 'Data Analytics',
        description:
            'Analyzing and interpreting data is frustrating. Allow us to make it simple for you! More Details Cost Report Data for any Skilled Nursing…',
        image: 'service3',
        hasButton: true,
    },
]

const ServicesHome = () => {
    return (
        <div className="flex flex-col items-center bg-backgroundpurple p-10">
            <h2 className="mb-2 text-[20px] uppercase text-healthpurple  xl:text-[30px]">
                Services
            </h2>
            <h2 className="text-[25px] font-extrabold text-white  xl:text-[30px]">
                What We Do
            </h2>
            <p className="mt-2 mb-2 mb-5 text-center text-white  xl:text-[20px]">
                "Expertly navigating the financial complexities of the
                healthcare industry for successful nursing homes"
            </p>
            <div className="flex flex-col lg:flex-row">
                {servicesOffered.map((service, index) => {
                    return (
                        <Service
                            title={service.title}
                            description={service.description}
                            image={service.image}
                            hasButton={service.hasButton}
                            key={index}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default ServicesHome
