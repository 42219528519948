import { NavLink } from 'react-router-dom'
import healthLogo from '../images/healthLogo.svg'

const Footer = () => {
    return (
        <footer className="relative flex flex-col bg-backgroundpurple px-10 pt-10 pb-2 md:flex-row md:justify-between">
            <div className="mb-5 flex">
                <img
                    src={healthLogo}
                    className="h-[50px]"
                    alt="healthcare systems and services logo"
                />
                <div className="ml-[10px]">
                    <h2 className="text-[20px] font-bold uppercase leading-none tracking-[1px] text-white xl:text-[30px]">
                        Healthcare
                    </h2>
                    <p className="text-[10px] uppercase leading-none tracking-[2.2px] text-white xl:text-[23px]">
                        Systems & Services
                    </p>
                </div>
                <div className="md:hidden"></div>
            </div>

            <div className="flex flex-col md:h-[200px] md:w-[350px] md:flex-row md:justify-between xl:w-[450px]">
                <div>
                    <h3 className="mb-5 text-[20px] uppercase text-white xl:mb-0 xl:text-[30px]">
                        Contact
                    </h3>
                    <ul className="items-center justify-center">
                        <li>
                            <NavLink
                                to="/"
                                className={({ isActive }) =>
                                    [
                                        'text-white xl:text-[20px]',
                                        isActive ? 'font-extrabold' : null,
                                    ]
                                        .filter(Boolean)
                                        .join(' ')
                                }
                            >
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="services"
                                className={({ isActive }) =>
                                    [
                                        'text-white xl:text-[20px]',
                                        isActive ? 'font-extrabold' : null,
                                    ]
                                        .filter(Boolean)
                                        .join(' ')
                                }
                            >
                                Services
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="team"
                                className={({ isActive }) =>
                                    [
                                        'text-white xl:text-[20px]',
                                        isActive ? 'font-extrabold' : null,
                                    ]
                                        .filter(Boolean)
                                        .join(' ')
                                }
                            >
                                About Us
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="contact"
                                className={({ isActive }) =>
                                    [
                                        'text-white xl:text-[20px]',
                                        isActive ? 'font-extrabold' : null,
                                    ]
                                        .filter(Boolean)
                                        .join(' ')
                                }
                            >
                                Contact
                            </NavLink>
                        </li>
                    </ul>
                </div>

                <div>
                    <h3 className="mt-5 mb-5 text-[20px] uppercase text-white md:mt-0 xl:mb-0 xl:text-[30px]">
                        Contact Details
                    </h3>
                    <ul className="text-white xl:text-[20px]">
                        <li>
                            <strong>Phone: </strong>
                            <a href="tel:6023008519">602 300-8519</a>
                        </li>
                        <li className="">
                            <strong>Email: </strong>
                            <a href="mailto:larrylitman@gmail.com">
                                larrylitman@gmail.com
                            </a>
                        </li>
                        <li className="">
                            <strong>Address: </strong>Phoenix, Arizona
                        </li>
                    </ul>
                </div>
            </div>

            <p className="mt-10 text-center text-[10px] text-white md:absolute md:bottom-1 md:left-[50%] md:translate-x-[-50%] xl:text-[15px]">
                Copyright &copy; 2022 Healthcare - All Rights Reserved
            </p>
        </footer>
    )
}

export default Footer
