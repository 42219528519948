import aboutFiller from '../images/aboutFiller1.webp'

const AboutInfo = () => {
    return (
        <section className="flex p-5 md:content-between md:p-40">
            <article className="mt-10 flex flex-col md:m-0 md:max-w-[80%] md:justify-center">
                <h3 className="text-[20px] font-bold leading-none text-backgroundpurple lg:text-[40px]">
                    We Will Fulfill
                </h3>
                <h3 className="text-[20px] font-bold leading-none text-backgroundpurple lg:text-[40px]">
                    All Your Accounting Needs
                </h3>
                <p className="mt-5 lg:text-[20px]">
                    With years of experience in the healthcare industry, our
                    team has the expertise and knowledge to handle all of your
                    accounting needs, from financial reporting to budgeting and
                    forecasting. We take the time to get to know your business
                    and your goals, so we can provide customized solutions that
                    meet your specific needs.
                </p>
                <div className="flex w-full justify-center md:hidden">
                    <img
                        src={aboutFiller}
                        className="mt-5"
                        alt="Person drawing on a tech screen with graphs and numbers"
                        loading="lazy"
                    />
                </div>
                <p className="mt-5  lg:text-[20px]">
                    In addition to traditional accounting services, we also
                    offer specialized support for the regulatory and compliance
                    issues that are unique to the nursing home industry. Our
                    team is up-to-date on all the latest laws and requirements,
                    and we work closely with you to ensure that your business is
                    in compliance and operating smoothly.
                </p>
            </article>

            <div className="flex hidden w-full justify-center md:visible md:m-auto md:mx-10 md:block">
                <img
                    src={aboutFiller}
                    className="mx-auto"
                    alt="Person drawing on a tech screen with graphs and numbers"
                    loading="lazy"
                />
            </div>
        </section>
    )
}

export default AboutInfo
