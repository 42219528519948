import service1 from '../../images/service1.webp'
import service2 from '../../images/service2.webp'
import service3 from '../../images/service3.webp'
import Button from '../Button'

type ServiceComponentProps = {
    title: string
    description: string
    image: string
    hasButton: boolean
}

const ServiceHome = ({
    title,
    description,
    image,
    hasButton,
}: ServiceComponentProps) => {
    const getCurrentImage = (image: string): string => {
        switch (image) {
            case 'service1':
                return service1
            case 'service2':
                return service2
            case 'service3':
                return service3
            default:
                return ''
        }
    }

    return (
        <div className="mb-8 flex flex-col items-center lg:p-5">
            {image.length ? (
                <img
                    src={getCurrentImage(image)}
                    alt="placeholder"
                    className="mb-5"
                    loading="lazy"
                />
            ) : (
                ''
            )}
            <h2 className="text-[25px] font-extrabold text-white xl:text-[30px]">
                {title}
            </h2>
            <p className="mt-2 mb-2 text-center text-white  xl:text-[20px]">
                {description}
            </p>
            {hasButton ? (
                <Button
                    action="services/"
                    text="Read More"
                    customStyles="text-white border-white border-[1px] xl:mt-3"
                    ariaText="Go to the services page"
                />
            ) : (
                ''
            )}
        </div>
    )
}

export default ServiceHome
