import { NavLink } from 'react-router-dom'

const Button = (props: {
    text: string
    action: string
    customStyles: string
    ariaText: string
}) => {
    return (
        <NavLink to={props?.action}>
            <button
                className={`w-[142px] p-2 font-sans text-[16px] uppercase tracking-wide xl:w-[200px] xl:text-[24px] ${props?.customStyles}`}
                aria-label={props?.ariaText || ''}
            >
                {props?.text}
            </button>
        </NavLink>
    )
}

export default Button
