import firstImage from '../images/firstcontent.webp'
import Button from './Button'

const FirstContent = () => {
    return (
        <section className="mt-10 mb-10 flex flex-col items-center sm:flex-row sm:justify-center sm:p-10 lg:m-0 lg:p-40">
            <img
                src={firstImage}
                alt="Generic Business People Looking at papers"
                loading="lazy"
                className="p-5"
            />
            <article className="max-w-[600px] p-5">
                <h2 className="text-[20px] uppercase tracking-[1px] text-healthpurple xl:text-[30px]">
                    Who We Are
                </h2>
                <h3 className="text-[20px] font-bold leading-none xl:text-[30px]">
                    We Will Fulfill
                </h3>
                <h3 className="text-[20px] font-bold leading-none xl:text-[30px]">
                    All Your Accounting Needs
                </h3>
                <p className="mt-[5px]  xl:text-[20px]">
                    Our team of experienced accountants understand the unique
                    financial needs of nursing homes and we are dedicated to
                    helping these facilities run smoothly and efficiently. We
                    offer a wide range of services including bookkeeping,
                    financial statement preparation, budgeting, and tax
                    planning.
                </p>
                <Button
                    action="team/"
                    customStyles="bg-healthpurple mt-[10px] text-white"
                    text="Read More"
                    ariaText="Go to the about page"
                />
            </article>
        </section>
    )
}

export default FirstContent
