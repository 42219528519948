import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

const Testimonials = () => {
    return (
        <section className="flex h-[300px] flex-col items-center p-10">
            <h2 className="mb-2 text-[20px] uppercase text-healthpurple xl:text-[30px]">
                Businesses Supported
            </h2>
            <h3 className="text-[20px] font-bold leading-none xl:text-[30px]">
                with our expertise
            </h3>
            <Carousel
                className="mt-10 w-full"
                showStatus={false}
                showThumbs={false}
                showArrows={false}
                autoPlay={true}
                infiniteLoop={true}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                    const defStyle = {
                        height: 10,
                        width: 10,
                        margin: 5,
                        backgroundColor: '#bbb',
                        borderRadius: '50%',
                        display: 'inline-block',
                        overflow: 'visible',
                    }
                    const style = isSelected
                        ? { ...defStyle, backgroundColor: '#6c63ff' }
                        : { ...defStyle }
                    return (
                        <span
                            style={style}
                            onClick={onClickHandler}
                            onKeyDown={onClickHandler}
                            key={index}
                            role="button"
                            tabIndex={0}
                            aria-label={`${label} ${index + 1}`}
                        ></span>
                    )
                }}
            >
                <div>
                    <div className="flex flex-col">
                        <h3 className="text-[15px] font-bold leading-none text-healthpurple xl:text-[20px]">
                            Villa Rita
                        </h3>
                        <p className="xl:text-[20px]">
                            We love working with Larry and Tyler!
                        </p>
                    </div>
                </div>
                <div>
                    <div className="flex flex-col">
                        <h3 className="text-[15px] font-bold leading-none text-healthpurple xl:text-[30px]">
                            Santa Maria
                        </h3>
                        <p className="xl:text-[20px]">
                            Larry has helped our accounting needs for over 20
                            years!
                        </p>
                    </div>
                </div>
                <div>
                    <div className="flex flex-col">
                        <h3 className="text-[15px] font-bold leading-none text-healthpurple xl:text-[30px]">
                            Immanuel
                        </h3>
                        <p className="xl:text-[20px]">
                            Larry and Tyler have helped our business financials
                            go from 0 to hero
                        </p>
                    </div>
                </div>
            </Carousel>
        </section>
    )
}

export default Testimonials
