import { useState } from 'react'
import { NavLink } from 'react-router-dom'

export function Navbar() {
    const [navbar, setNavbar] = useState(false)

    return (
        <nav className="w-full bg-gray-50">
            <div className="justify-between px-4 py-3 md:flex md:items-center md:px-8">
                <div className="">
                    <div className="flex items-center justify-between py-1 md:block md:py-3">
                        <a href="/" className="flex items-center">
                            <svg
                                width="37"
                                height="34"
                                viewBox="0 0 37 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="xl:h-[68px] xl:w-[70px]"
                            >
                                <path
                                    d="M14.7628 23.538L8.68726 11.2275L7.13073 11.7943L13.2063 24.1047L14.7628 23.538Z"
                                    fill="#E2E0FF"
                                />
                                <path
                                    d="M23.0582 24.0975L29.1335 11.7876L27.577 11.2208L21.5017 23.5307L23.0582 24.0975Z"
                                    fill="#4C4C78"
                                />
                                <path
                                    d="M6.02201 13.9746C9.34788 13.9746 12.044 10.8463 12.044 6.9873C12.044 3.12832 9.34788 0 6.02201 0C2.69615 0 0 3.12832 0 6.9873C0 10.8463 2.69615 13.9746 6.02201 13.9746Z"
                                    fill="#6C63FF"
                                />
                                <path
                                    d="M26.9767 5.56494H11.4102V7.01707H26.9767V5.56494Z"
                                    fill="#6C63FF"
                                />
                                <path
                                    d="M30.252 13.9746C33.5781 13.9746 36.2744 10.8463 36.2744 6.9873C36.2744 3.12832 33.5781 0 30.252 0C26.9258 0 24.2295 3.12832 24.2295 6.9873C24.2295 10.8463 26.9258 13.9746 30.252 13.9746Z"
                                    fill="#4C4C78"
                                />
                                <path
                                    d="M18.1372 33.6987C21.4633 33.6987 24.1597 30.5696 24.1597 26.7097C24.1597 22.8498 21.4633 19.7207 18.1372 19.7207C14.8111 19.7207 12.1147 22.8498 12.1147 26.7097C12.1147 30.5696 14.8111 33.6987 18.1372 33.6987Z"
                                    fill="#E2E0FF"
                                />
                            </svg>
                            <div className="ml-[10px]">
                                <h2 className="text-[20px] font-bold uppercase leading-none tracking-[1px] text-healthpurple xl:text-[40px]">
                                    Healthcare
                                </h2>
                                <p className="text-[10px] uppercase leading-none tracking-[2.2px] text-systemblack  xl:text-[23px]">
                                    Systems & Services
                                </p>
                            </div>
                        </a>
                        <div className="md:hidden">
                            <button
                                className="rounded-md p-2 text-black outline-none focus:border focus:border-gray-400"
                                onClick={() => setNavbar(!navbar)}
                            >
                                {navbar ? (
                                    <svg
                                        width="28"
                                        height="22"
                                        viewBox="0 0 28 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M27 1H15.4444M27 11H8.22222M27 21H1"
                                            stroke="#222222"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        width="28"
                                        height="22"
                                        viewBox="0 0 28 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M27 1H15.4444M27 11H8.22222M27 21H1"
                                            stroke="#222222"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        className={`mt-2 flex-1 justify-self-center pb-3 md:mt-0 md:block md:pb-0 ${
                            navbar ? 'block' : 'hidden'
                        }`}
                    >
                        <ul className="items-center justify-center space-y-2 md:flex md:space-x-6 md:space-y-0">
                            <li>
                                <NavLink
                                    to="/"
                                    className={({ isActive }) =>
                                        [
                                            'text-black xl:text-[25px]',
                                            isActive ? 'font-extrabold' : null,
                                        ]
                                            .filter(Boolean)
                                            .join(' ')
                                    }
                                >
                                    Home
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="services"
                                    className={({ isActive }) =>
                                        [
                                            'text-black xl:text-[25px]',
                                            isActive ? 'font-extrabold' : null,
                                        ]
                                            .filter(Boolean)
                                            .join(' ')
                                    }
                                >
                                    Services
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="team"
                                    className={({ isActive }) =>
                                        [
                                            'text-black xl:text-[25px]',
                                            isActive ? 'font-extrabold' : null,
                                        ]
                                            .filter(Boolean)
                                            .join(' ')
                                    }
                                >
                                    About Us
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="contact"
                                    className={({ isActive }) =>
                                        [
                                            'text-black xl:text-[25px]',
                                            isActive ? 'font-extrabold' : null,
                                        ]
                                            .filter(Boolean)
                                            .join(' ')
                                    }
                                >
                                    Contact
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}
