import ContactSnippit from './components/ContactSnippit'
import FirstContent from './components/FirstContent'
import Hero from './components/Hero'
import Services from './components/services/ServicesHome'
import Testimonials from './components/Testimonials'
import Trophy from './components/Trophy'
import { ImageOptions } from './models'

export function Home() {
    return (
        <div>
            <Hero title="Home" isMain={true} image={ImageOptions.home} />
            <FirstContent />
            <Services />
            <Testimonials />
            <Trophy />
            <ContactSnippit />
        </div>
    )
}
