import { BaseSyntheticEvent, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'

import phoneLogo from '../images/phoneLogo.svg'
import emailLogo from '../images/emailLogo.svg'
import locationLogo from '../images/locationLogo.svg'

const Contact = () => {
    const form = useRef<any>()
    const [emailFeedback, setEmailFeedback] = useState('')
    const [formState, setFormState] = useState({
        user_name: '',
        user_email: '',
        user_message: '',
    })

    const handleInput = (e: BaseSyntheticEvent) => {
        setFormState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const sendEmail = (e: any) => {
        e.preventDefault()
        emailjs
            .sendForm(
                'service_sfzw1f9',
                'template_36233nb',
                form.current,
                process.env.REACT_APP_EMAIL_API_KEY
            )
            .then(
                () => {
                    setFormState({
                        user_name: '',
                        user_email: '',
                        user_message: '',
                    })
                    setEmailFeedback('*Your message has been sent*')
                    setTimeout(() => {
                        setEmailFeedback('')
                    }, 3000)
                },
                (error) => {
                    setEmailFeedback('An Error Occured')
                    setTimeout(() => {
                        setEmailFeedback('')
                    }, 3000)
                    console.log(error.text)
                }
            )
    }

    return (
        <section className="mt-40 lg:m-0">
            <div className="flex flex-col lg:my-10 lg:flex-row lg:justify-around lg:py-32">
                <div className="flex h-[450px] w-full flex-col justify-around bg-backgroundpurple text-white lg:max-w-[450px]">
                    <div className="ml-10 flex w-[165px] justify-between text-[20px]">
                        <img
                            src={phoneLogo}
                            alt="small logo of a phone"
                            loading="lazy"
                            className="h-[26px] w-[26px]"
                        />
                        <a href="tel:6023008519">602 300-8519</a>
                    </div>

                    <div className="ml-10 flex w-[260px] justify-between text-[20px]">
                        <img
                            src={emailLogo}
                            alt="small logo of an email"
                            className="my-auto h-[26px] w-[26px]"
                            loading="lazy"
                        />
                        <a href="mailto:larrylitman@gmail.com">
                            LarryLitman@gmail.com
                        </a>
                    </div>

                    <div className="ml-10 flex w-[190px] justify-between text-[20px]">
                        <img
                            src={locationLogo}
                            alt="small logo of a geolocation tag"
                            className="h-[26px] w-[26px]"
                            loading="lazy"
                        />
                        <p>Phoenix, Arizona</p>
                    </div>
                </div>

                <div className="flex p-10 md:flex-col md:content-center">
                    <form
                        ref={form}
                        className="w-full text-[30px] md:m-auto md:w-[450px] xl:w-[700px]"
                        onSubmit={sendEmail}
                    >
                        <div>
                            <input
                                type="text"
                                className="w-full border-b-2 border-[#C4C4C4] outline-0"
                                name="user_name"
                                placeholder="Name"
                                onChange={(e) => handleInput(e)}
                                value={formState.user_name}
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="email"
                                className="mt-5 w-full border-b-2 border-[#C4C4C4] outline-0"
                                aria-describedby="emailHelp"
                                placeholder="Email"
                                onChange={(e) => handleInput(e)}
                                name="user_email"
                                value={formState.user_email}
                                required
                            />
                        </div>
                        <div>
                            <textarea
                                className="mt-5 h-[100px] w-full border-b-2 border-[#C4C4C4] outline-0"
                                name="user_message"
                                placeholder="Message"
                                onChange={(e) => handleInput(e)}
                                value={formState.user_message}
                                required
                            />
                        </div>
                        <button
                            type="submit"
                            className={`mt-5 w-[121px] bg-[#6C63FF] p-2 font-sans text-[16px] uppercase tracking-wide text-white xl:w-[200px] xl:text-[24px]`}
                        >
                            Send
                        </button>
                    </form>
                    {emailFeedback && <p>{emailFeedback}</p>}
                </div>
            </div>

            <div className="flex h-[350px] items-center justify-center bg-arizona bg-cover bg-fixed bg-center bg-no-repeat lg:h-[491px]"></div>
        </section>
    )
}

export default Contact
