import service1 from '../../images/service1.webp'
import service2 from '../../images/service2.webp'
import service3 from '../../images/service3.webp'
import Button from '../Button'

const ServicesHome = () => {
    return (
        <div className="flex flex-col p-10">
            <div className="mt-10 flex flex-col lg:flex-row lg:justify-around lg:px-10">
                <div className="lg:max-w-[500px]">
                    <h2 className="text-[25px] font-bold text-backgroundpurple">
                        Annual Services
                    </h2>
                    <p className="mt-3 text-[18px]">
                        We provide a number of yearly services such as cost
                        report preparation, year-end audits, "what-if" scenarios
                        and location specific Medicare Reimbursement Rate
                        Schedules.
                    </p>
                    <ol className="mt-5 list-decimal text-[18px]">
                        <li>Federal and State cost report preparation</li>
                        <li>
                            Preparation and Documentation of year-end audits
                        </li>
                        <li>
                            Preparation of working annual budgets with 'what-if'
                            scenario development
                        </li>
                        <li>
                            Provide location specific Medicare Reimbursment Rate
                            Schedules
                        </li>
                    </ol>
                    <Button
                        action="/contact/"
                        text="Contact Us"
                        customStyles="border-backgroundpurple text-backgroundpurple border-[1px] w-[170px] mt-4"
                        ariaText="Go to the contact page"
                    />
                </div>
                <img
                    src={service1}
                    className="mt-6 max-w-[600px] lg:w-[500px]"
                    alt="man writing on a document with graphs and diagrams"
                    loading="lazy"
                />
            </div>

            <div className="mt-10 flex flex-col lg:mt-40 lg:flex-row-reverse lg:justify-around lg:px-10">
                <div className="lg:ml-5 lg:max-w-[500px] 2xl:max-w-[800px]">
                    <h2 className="text-[25px] font-bold text-backgroundpurple">
                        Monthly Services
                    </h2>
                    <p className="mt-3 text-[18px]">
                        For monthly services we provide financial statement
                        preparation, monthly business audits for accuracy of
                        ancillary services, specialty reports and more.
                    </p>
                    <ol className="mt-5 list-decimal text-[18px]">
                        <li>
                            Monthly financial statement preparation including
                            reconciliation of all balance sheet accounts
                        </li>
                        <li>
                            Audits of business office input for accuracy of
                            ancillary services and monthly recording of patient
                            revenue
                        </li>
                        <li>
                            Specialty reports prepared monthly
                            <ul className="ml-5 list-disc">
                                <li>
                                    Analysis reports (trended over a 12 month
                                    period) designed to add another more
                                    complete level of understanding to your
                                    financial statement results that you will
                                    not see anywhere else
                                </li>
                                <li>
                                    Which patient types hinder your success?
                                </li>
                                <li>
                                    When is an empty bet the right approach?
                                </li>
                                <li>Which payers make you the most money?</li>
                                <li>
                                    How important is resource contouring to your
                                    operational success?
                                </li>
                                <li>
                                    How well are you moving patients through the
                                    PDPM systems?
                                </li>
                            </ul>
                        </li>
                        <li>
                            Provide documentation for HMO contract evaluation
                        </li>
                    </ol>
                    <Button
                        action="/contact/"
                        text="Contact Us"
                        customStyles="border-backgroundpurple text-backgroundpurple border-[1px] w-[170px] mt-4"
                        ariaText="Go to the contact page"
                    />
                </div>
                <img
                    src={service2}
                    className="mt-6 max-w-[600px] lg:my-auto lg:max-h-[334px] lg:w-[500px]"
                    alt="man sitting down looking at a phone and calculator"
                    loading="lazy"
                />
            </div>

            <div className="mt-10 flex flex-col lg:mt-40 lg:flex-row lg:justify-around lg:px-10">
                <div className="lg:max-w-[500px]">
                    <h2 className="text-[25px] font-bold text-backgroundpurple">
                        Data Analytics
                    </h2>
                    <p className="mt-3 text-[18px]">
                        Analyzing and interpreting data is frustrating. Allow us
                        to make it simple for you!
                    </p>
                    <ol className="mt-5 list-decimal text-[18px]">
                        <li>
                            Cost Report Data for any Skilled Nursing Facility
                            nationwide
                        </li>
                        <li>Compilation and Analysis of cost report data</li>
                        <li>
                            Need help with something else? Send us an email annd
                            we will get back to you.
                        </li>
                    </ol>
                    <Button
                        action="/contact/"
                        text="Contact Us"
                        customStyles="border-backgroundpurple text-backgroundpurple border-[1px] w-[170px] mt-4"
                        ariaText="Go to the contact page"
                    />
                </div>
                <img
                    src={service3}
                    className="mt-6 max-w-[600px] lg:w-[500px]"
                    alt="man writing on a document with graphs and diagrams"
                    loading="lazy"
                />
            </div>
        </div>
    )
}

export default ServicesHome
