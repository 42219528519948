const Trophy = () => {
    return (
        <section className="image-overlay h-[1018px] w-full bg-trophyMobile bg-cover bg-fixed bg-center bg-no-repeat sm:h-[421px] sm:bg-trophyDesktop">
            <div className="flex h-full flex-col justify-around text-center sm:flex-row sm:items-center">
                <div>
                    <h2 className="text-[100px] font-bold text-healthpurple">
                        30+
                    </h2>
                    <h2 className="text-[25px] text-white">
                        Years of Experience
                    </h2>
                </div>
                <div>
                    <h2 className="text-[100px] font-bold text-healthpurple">
                        20+
                    </h2>
                    <h2 className="text-[25px] text-white">
                        Buildings Supported
                    </h2>
                </div>
                <div>
                    <h2 className="text-[100px] font-bold text-healthpurple">
                        10+
                    </h2>
                    <h2 className="text-[25px] text-white">Services Offered</h2>
                </div>
            </div>
        </section>
    )
}

export default Trophy
