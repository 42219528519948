import { Routes, Route } from 'react-router-dom'
import { Home } from './Home'
import { Navbar } from './components/Navbar'
import Footer from './components/Footer'
import { ContactPage } from './Contact'
import { TeamPage } from './TeamPage'
import { ServicesPage } from './ServicesPage'

function App() {
    return (
        <div>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="services/" element={<ServicesPage />} />
                <Route path="team/" element={<TeamPage />} />
                <Route path="contact/" element={<ContactPage />} />
            </Routes>
            <Footer />
        </div>
    )
}

export default App
