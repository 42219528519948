import Contact from './components/Contact'
import Hero from './components/Hero'
import { ImageOptions } from './models'

export function ContactPage() {
    return (
        <div>
            <Hero
                title="Contact Us"
                isMain={false}
                image={ImageOptions.contact}
            />
            <Contact />
        </div>
    )
}
