import ContactSnippit from './components/ContactSnippit'
import Hero from './components/Hero'
import Services from './components/services/Services'
import { ImageOptions } from './models'

export function ServicesPage() {
    return (
        <div>
            <Hero
                title="Services We Offer"
                isMain={false}
                image={ImageOptions.service}
            />
            <Services />
            <ContactSnippit />
        </div>
    )
}
